<template>
  <v-card>
    <v-card-title class="headline"
      >Confirmar acción
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <h3 class="mt-5">
        ¿Está seguro que desea {{ tab == 0 ? "eliminar" : "cancelar" }} la push
        notification <b class="font-weight-bold">{{ item.title }}</b
        >?
      </h3>
    </v-card-text>

    <v-card-actions style="justify-content: center">
      <v-row class="pa-0 ma-0">
        <v-col cols="12" sm="6" md="6">
          <v-btn
            color="gray"
            class="mb-3"
            rounded
            block
            height="50"
            :loading="loading"
            @click="$emit('cancel')"
          >
            Salir
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-btn
            color="red darken-4 white--text"
            class="mb-3"
            block
            height="50"
            :loading="loading"
            @click="$emit('confirm')"
          >
            {{ tab == 0 ? "Eliminar" : "Cancelar" }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["item", "loading", "tab"],
  name: "cancel-push",
};
</script>

<style scoped>
</style>