<template>
  <div>
    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">
          Crear notificación por {{ type | translate }}
        </span>

        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-snackbar top :timeout="3000" v-model="snackbar">
            {{ snackbarText }}
          </v-snackbar>
          <v-overlay class="loading-center" :value="saving">
            <Lottie
              :options="defaultOptions"
              :height="300"
              :width="400"
              v-on:animCreated="handleAnimation"
            />
          </v-overlay>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-col cols="12" sm="12" md="12">
                <div
                  :style="imgSrc ? `border: 1px solid rgb(214, 214, 214);` : ''"
                  class="image-container"
                >
                  <img @click.prevent="showFileChooser" :src="getUrlImage" />
                </div>
              </v-col>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-col cols="12" sm="12" md="12">
                <p>Título</p>
                <input
                  class="control-input"
                  type="text"
                  placeholder="Ingrese el título de notificación"
                  v-model.trim="notification.title"
                />
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <p>Contenido</p>
                <v-textarea
                  outlined
                  style="border-radius: 50px"
                  name="input-7-3"
                  label="Ingrese el contenido de notificación"
                  v-model.trim="notification.body"
                ></v-textarea>
              </v-col>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <p>Seleccionar día para enviar la notificación</p>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <input
                    type="text"
                    v-on="on"
                    class="control-input"
                    placeholder="Seleccionar día"
                    v-model="date"
                  />
                </template>
                <v-date-picker
                  v-model="date"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <p>Seleccionar hora para enviar la notificación</p>
              <input
                type="time"
                class="control-input"
                id="appt"
                name="appt"
                min="01:00"
                max="23:59"
                v-model="time"
              />
            </v-col>

            <v-col cols="12" v-if="type === 'city'">
              <p>Seleccionar Ciudad(es)</p>
              <v-select
                v-model="notification.cities"
                :items="cities"
                chips
                deletable-chips
                label="Ciudad(es)"
                multiple
                outlined
                item-text="name"
                :item-value="'id'"
                rounded
              />
            </v-col>
            <v-col cols="12" v-else>
              <p>Seleccionar Usuario(s)</p>
              <v-autocomplete
                chips
                clearable
                deletable-chips
                multiple
                rounded
                outlined
                v-model="notification.users"
                :items="items"
                :item-text="(item) => `${item.email}(${item.phone})`"
                :item-value="'id'"
                :loading="loading"
                :search-input.sync="search"
                cache-items
                hide-no-data
                hide-details
                label="Busca por correo o # de celular (+504...)"
                @change="clearSearch()"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <p>Seleccionar tipo de notificación</p>
              <v-autocomplete
                clearable
                rounded
                outlined
                v-model="selectedType"
                :items="types"
                :loading="loading"
                hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col
              v-if="selectedType == 'business' || selectedType == 'product'"
              cols="12"
              sm="12"
              md="12"
            >
              <p>Seleccionar Ciudad</p>
              <v-autocomplete
                clearable
                rounded
                outlined
                v-model="selectedCity"
                :items="citiesArray"
                item-text="name"
                item-value=".key"
                :loading="loading"
                hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col
              v-if="selectedType == 'business' || selectedType == 'product'"
              cols="12"
              sm="12"
              md="12"
            >
              <p>Seleccionar categoría</p>
              <v-autocomplete
                clearable
                rounded
                outlined
                item-text="name"
                item-value=".key"
                v-model="selectedCategory"
                :items="sortedCategories"
                :loading="loading"
                hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col
              v-if="selectedType == 'business' || selectedType == 'product'"
              cols="12"
              sm="12"
              md="12"
            >
              <p>Seleccionar comercio</p>
              <v-autocomplete
                clearable
                hint="Solo se muestran los comercios que están abiertos en la aplicación."
                rounded
                outlined
                item-text="shortName"
                item-value=".key"
                v-model="selectedBusiness"
                :items="sortedBusinesses"
                :loading="loading"
              >
              </v-autocomplete>
            </v-col>

            <v-col v-if="selectedType == 'product'" cols="12" sm="12" md="12">
              <p>Seleccionar producto</p>
              <v-autocomplete
                clearable
                rounded
                outlined
                item-text="name"
                item-value=".key"
                v-model="selectedProduct"
                :items="sortedProducts"
                :loading="loading"
                hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col v-if="selectedType == 'url'" cols="12" sm="12" md="12">
              <p>Ingrese la URL</p>
              <v-text-field v-model="url" rounded outlined />
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0 mt-8">
            <v-col cols="12" sm="6">
              <v-switch
                :loading="loading"
                v-model="sendNotification"
                label="Enviar Notificación"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="6">
              <v-row justify="end">
                <v-btn
                  class="save-btn mt-5"
                  color="primary"
                  style="min-width: 120px"
                  @click="createNotification()"
                >
                  Crear
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <input
      class="upload"
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="imageChanged"
    />
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";

export default {
  name: "add-push-notification",
  mixins: [lottieSettings],
  components: {
    Lottie,
  },
  props: {
    cities: {
      required: true,
      type: Array,
    },
    type: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      date: "",
      time: "",
      notification: {
        title: "",
        body: "",
        cities: [],
        users: [],
      },
      loading: false,
      saving: false,
      sendNotification: true,
      url: "",
      search: null,
      items: [],
      categories: [],
      selectedCategory: "",
      imageFile: null,
      imgSrc: "",
      selectedType: "",
      citiesArray: [],
      selectedCity: "",
      selectedBusiness: "",
      businesses: [],
      selectedProduct: "",
      products: [],
      menu: "",
      types: [
        { text: "Comercio", value: "business" },
        { text: "Producto", value: "product" },
        { text: "URL", value: "url" },
      ],
    };
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },

    selectedType(val) {
      if (val == "business" || val == "product") {
        this.$binding(
          "citiesArray",
          db
            .collection("cities")
            .where("active", "==", true)
            .where("deleted", "==", false)
          // .orderBy("name")
        );
      }
    },
    selectedCity(cityId) {
      this.$binding(
        "categories",
        db
          .collection("categories")
          .where("city", "==", cityId)
          .where("active", "==", true)
          .where("deleted", "==", false)
        // .orderBy("name")
      );
    },
    selectedCategory(categoryId) {
      let query = db
        .collection("businesses")
        .where("ochoCategories", "array-contains", categoryId)
        .where("active", "==", true)
        .where("deleted", "==", false);

      if (!this.date && !this.time) {
        query = query.where("closed", "==", false).where("isOpen", "==", true);
      }

      this.$binding("businesses", query);
    },

    selectedBusiness(businessId) {
      this.$binding(
        "products",
        db
          .collection("products")
          .where("business", "array-contains", businessId)
          .where("active", "==", true)
          .where("deleted", "==", false)
          .where("addon", "==", false)
        // .orderBy("name")
      );
    },
  },
  methods: {
    imageChanged(e) {
      this.imageFile = e.target.files[0];

      if (this.imageFile.type.indexOf("image/") === -1) {
        alert("Por favor seleccionar una imagen");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // this.modalUploadImage = true;
        };

        reader.readAsDataURL(this.imageFile);
      } else {
        alert("Lo sentimos, formato no soportado");
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    generateTimestamp(data, source) {
      let date = data.split("-");
      console.log(date);
      return fb.firestore.Timestamp.fromDate(
        new Date(
          parseInt(date[0]),
          parseInt(date[1]) - 1,
          parseInt(date[2]),
          parseInt(source == "from" ? "00" : "23"),
          parseInt(source == "from" ? "01" : "59"),
          parseInt("00")
        )
      );
    },
    scheduledTime() {
      return new Promise((resolve, reject) => {
        if (this.time && this.date) {
          let date = this.date.split("-");
          let time = this.time.split(":");

          return resolve(
            fb.firestore.Timestamp.fromDate(
              new Date(
                parseInt(date[0]),
                parseInt(date[1]) - 1,
                parseInt(date[2]),
                parseInt(time[0]),
                parseInt(time[1]),
                parseInt(30)
              )
            )
          );
        } else {
          return resolve("");
        }
      });
    },
    async createNotification() {
      let scheduledTime = await this.scheduledTime();

      let validation = await this.validateNotification();
      if (validation) {
        let data = {
          createdAt: new Date(),
          type: this.type,
          deepLinkType: this.selectedType,
          url: this.url,
          businessId: this.selectedBusiness,
          categoryId: this.selectedCategory,
          productId: this.selectedProduct,
          createdBy: this.$store.state.user[".key"],
          cities: this.notification.cities,
          users: this.notification.users,
          title: this.notification.title,
          body: this.notification.body,
          sendNotification: this.sendNotification,
        };

        if (scheduledTime) {
          data.scheduledTime = scheduledTime;
          data.status = "pending";
        }

        if (confirm("¿Estás seguro de enviar notificación?")) {
          this.saving = true;

          db.collection(
            scheduledTime ? "scheduledPushNotifications" : "pushNotifications"
          )
            .add(data)
            .then(async (ref) => {
              if (this.imageFile) {
                await this.saveImage(ref.id);
              }
              this.saving = false;
              this.$emit("success", "Notificación creada exitosamente.");
            })
            .catch((err) => {
              this.saving = false;
              this.snackbarText = `Ocurrió un error inesperado, inténtelo nuevamente, ${err}`;
              this.snackbar = true;
            });
        }
      }
    },

    saveImage(pushNotificationId) {
      return new Promise((resolve, reject) => {
        let bucket = `gs://${process.env.VUE_APP_PROJECT_ID}-push`;
        let split = this.imageFile.type.split("/");
        let type = split.length && split[1] ? split[1] : "jpg";

        fb.app()
          .storage(bucket)
          .ref()
          .child(`${pushNotificationId}/img.${type}`)
          .put(this.imageFile)
          .then(() => {
            resolve(true);
          })
          .catch((err) => {
            resolve(true);
            // this.snackbar = true;
            // this.snackbarText = "Ocurrió un error al cargar la imágen.";
          });
      });
    },

    validateNotification() {
      return new Promise((resolve, reject) => {
        if (!this.notification.title) {
          this.snackbarText = "Ingrese el título de la notificación.";
          this.snackbar = true;
          return resolve(false);
        }

        if (!this.notification.body) {
          this.snackbarText = "Ingrese el contenido de la notificación.";
          this.snackbar = true;
          return resolve(false);
        }

        if (this.selectedArraytype.length === 0) {
          this.snackbarText =
            this.type == "city"
              ? "Seleccione las ciudades"
              : "Seleccione los usuarios a notificar.";
          this.snackbar = true;
          return resolve(false);
        }

        if (this.selectedType) {
          switch (this.selectedType) {
            case "business":
              if (!this.selectedCategory) {
                this.snackbarText = "Seleccione la categoría del comercio.";
                this.snackbar = true;
                return resolve(false);
              }

              if (!this.selectedBusiness) {
                this.snackbarText = "Seleccione el comercio.";
                this.snackbar = true;
                return resolve(false);
              }
              break;

            case "product":
              if (!this.selectedCategory) {
                this.snackbarText = "Seleccione la categoría del comercio.";
                this.snackbar = true;
                return resolve(false);
              }

              if (!this.selectedBusiness) {
                this.snackbarText = "Seleccione el comercio.";
                this.snackbar = true;
                return resolve(false);
              }

              if (!this.selectedProduct) {
                this.snackbarText = "Seleccione el producto.";
                this.snackbar = true;
                return resolve(false);
              }
              break;

            case "url":
              if (!this.url) {
                this.snackbarText = "Ingrese la URL.";
                this.snackbar = true;
                return resolve(false);
              }
              break;
            default:
              break;
          }
        }

        resolve(true);
      });
    },
    async querySelections(query) {
      this.loading = true;
      const userRef = db.collection("users");
      const byEmail = userRef.where("email", ">=", query).limit(5).get();

      const byPhone = userRef
        .where("phone", ">=", query)
        .where("phone", "<=", query + "\uf8ff")
        .limit(5)
        .get();

      const [emailQuerySnapshot, phoneQuerySnapshot] = await Promise.all([
        byEmail,
        byPhone,
      ]);

      const emailsArray = emailQuerySnapshot.docs;
      const phonesrray = phoneQuerySnapshot.docs;
      const usersArray = emailsArray.concat(phonesrray);

      usersArray.forEach((user) => {
        this.items.push({
          id: user.id,
          name: user.data().name,
          email: user.data().email,
          phone: user.data().phone,
        });
      });
      this.loading = false;
    },
    clearSearch() {
      this.search = "";
    },
  },
  filters: {
    translate(type) {
      if (type == "city") {
        return "ciudad(es)";
      } else {
        return "usuario(s)";
      }
    },
  },
  computed: {
    selectedArraytype() {
      if (this.type == "city") {
        return this.notification.cities;
      } else {
        return this.notification.users;
      }
    },
    sortedCategories: function () {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      return this.categories.sort(compare);
    },
    sortedProducts: function () {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      return this.products.sort(compare);
    },

    sortedBusinesses: function () {
      function compare(a, b) {
        if (a.shortName < b.shortName) return -1;
        if (a.shortName > b.shortName) return 1;
        return 0;
      }

      return this.businesses.sort(compare);
    },

    getUrlImage() {
      if (this.imgSrc) {
        return this.imgSrc;
      } else {
        return "https://firebasestorage.googleapis.com/v0/b/el-ocho-data/o/product-default.jpg?alt=media&token=2936fc1f-b1de-49f8-81dd-8ad132ef52df";
      }
    },
  },
};
</script>

<style scoped>
.image-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 300px;
}
.image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
}

.upload {
  display: none;
}
</style>
