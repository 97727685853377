<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <!-- <v-overlay class="loading-center" :value="loading">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay> -->

    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="8" md="8">
        <h1>Push Notifications</h1>
      </v-col>

      <v-col cols="12" sm="4" md="4">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="date"
              label="Filtrar por fecha"
              prepend-icon="fa-calendar-alt"
              readonly
              filled
              rounded
              hide-details
              :disabled="loading"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            range
            :title-date-format="title"
            scrollable
            :max="today"
          >
            <v-row no-gutters style="flex-wrap: nowrap">
              <v-btn
                class="flex-grow-1 flex-shrink-0"
                large
                rounded
                outlined
                color="primary"
                @click="modal = false"
                >Cancelar</v-btn
              >
              <v-btn
                class="flex-grow-1 flex-shrink-0"
                large
                rounded
                color="primary"
                @click="$refs.dialog.save(date)"
              >
                Filtrar
              </v-btn>
            </v-row>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row class="px-2">
      <v-col cols="12">
        <v-row justify="end">
          <v-btn
            color="primary"
            class="ma-2 white--text"
            @click="selectNotificationType()"
            :loading="loading"
          >
            <v-icon right dark class="mr-3">fas fa-plus</v-icon>
            Crear notificación
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="12" md="12">
        <v-tabs v-model="tab" centered>
          <v-tab>Notificaciones Enviadas</v-tab>
          <v-tab>Notificaciones Programadas</v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="getHeaders"
          :items="pushNotifications"
          :search="$store.state.search"
          :items-per-page="10"
          :loading="loading"
          :sort-desc="true"
          sort-by="createdAt"
          item-key=".key"
          :single-expand="true"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div class="inner-lottie">
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p class="subtitle-1 black--text">Cargando Data</p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | formatDate }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              v-if="item.status == 'success'"
              class="white--text"
              color="green"
              >Enviada</v-chip
            >
            <v-chip
              v-if="item.status == 'pending'"
              class="white--text"
              color="rgb(190 161 9)"
              >Pendiente</v-chip
            >
            <v-chip
              v-if="item.status == 'canceled'"
              class="white--text"
              color="red"
              >Cancelada</v-chip
            >
          </template>

          <template v-slot:[`item.scheduledTime`]="{ item }">
            {{ item.scheduledTime | formatFullDate }}
          </template>

          <template v-slot:[`item.body`]="{ item }">
            <div class="text-truncate" style="max-width: 25vw">
              {{ item.body }}
            </div>
          </template>

          <template v-slot:[`item.successCount`]="{ item }">
            {{ getVerbiage(item.successCount, item.sendNotification) }}
          </template>

          <template v-slot:[`item.cities`]="{ item }">
            {{ getCityNames(item.cities) }}
          </template>

          <template v-slot:[`item.users`]="{ item }">
            {{ item.userInfo | toSentece }}
          </template>

          <template v-slot:[`item.createdBy`]="{ item }">
            {{ getUserName(item.createdBy) }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip v-if="tab == 1 && item.status == 'pending'" left>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mx-1"
                  fab
                  v-on="on"
                  dark
                  @click="cancel(item)"
                  x-small
                  :elevation="0"
                  color="red"
                >
                  <v-icon dark>fas fa-ban</v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>

            <v-tooltip v-if="tab == 0" left>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mx-1"
                  fab
                  v-on="on"
                  dark
                  @click="cancel(item)"
                  x-small
                  :elevation="0"
                  color="red"
                >
                  <v-icon dark>fas fa-trash</v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="selectTypeModal" max-width="500px">
      <v-card>
        <v-card-title class="grid-close">
          <span class="headline"> Seleccionar destinatario </span>

          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="closeSelectTypeModal()"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container fluid>
            <v-row justify="center">
              <v-col cols="12" sm="12" md="12">
                <v-btn
                  block
                  color="primary"
                  class="white--text"
                  min-height="50"
                  @click="
                    newNotification('user');
                    selectTypeModal = false;
                  "
                  :loading="loading"
                >
                  <v-icon right dark class="mr-3">fas fa-users</v-icon>
                  Por usuario
                </v-btn>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-btn
                  block
                  color="primary"
                  class="white--text"
                  min-height="50"
                  @click="
                    newNotification('city');
                    selectTypeModal = false;
                  "
                  :loading="loading"
                >
                  <v-icon right dark class="mr-3">fas fa-city</v-icon>
                  Por ciudad
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="addModal" max-width="900px">
      <add-push-notification
        @cancel="addModal = false"
        @success="handleSuccess"
        :key="randomKey"
        :cities="cities"
        :type="type"
      ></add-push-notification>
    </v-dialog>

    <v-dialog persistent v-model="cancelDialog" max-width="500px">
      <confirm
        @confirm="tab == 0 ? deletePush() : cancelPush()"
        :tab="tab"
        @cancel="cancelDialog = false"
        :item="selectedItem"
      >
      </confirm>
    </v-dialog>
  </v-container>
</template>

<script>
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import AddPushNotification from "./add-push-notification.vue";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { db } from "@/firebase";
import confirm from "./confirm";

export default {
  name: "push-notifications",
  components: {
    lottie: Lottie,
    AddPushNotification,
    confirm,
  },
  mixins: [lottieSettings],
  data() {
    return {
      loading: true,
      snackbarText: "",
      snackbar: false,
      randomKey: 0,
      tab: 0,
      addModal: false,
      selectTypeModal: false,
      type: "",
      pushNotifications: [],
      selectedItem: null,
      cancelDialog: false,
      users: [],
      cities: [],
      modal: null,
      date: [
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
      ],
      headers: [
        {
          text: "Estado",
          value: "status",
          tabs: [1],
        },
        {
          text: "Título",
          value: "title",
          width: "200px",
          tabs: [0, 1],
        },
        {
          text: "Contenido",
          value: "body",
          tabs: [0, 1],
        },
        {
          text: "Ciudad(es)",
          value: "cities",
          tabs: [0, 1],
        },
        {
          text: "Enviados",
          value: "successCount",
          tabs: [0],
        },
        {
          text: "Usuario(s)",
          value: "users",
          tabs: [0, 1],
        },
        {
          text: "Fecha de programación",
          value: "scheduledTime",
          tabs: [1],
        },
        {
          text: "Creada",
          value: "createdAt",
          // width: "200px",
          tabs: [0, 1],
        },
        {
          text: "Creada por",
          value: "createdBy",
          // width: "100px",
          tabs: [0, 1],
        },
        {
          text: "",
          value: "actions",
          // width: "100px",
          tabs: [0, 1],
          align: "end",
        },
      ],
    };
  },
  computed: {
    getHeaders() {
      return this.headers.filter((i) => i.tabs.includes(this.tab));
    },
    today() {
      return new Date().toISOString().substr(0, 10);
    },
  },
  async mounted() {
    this.$store.commit("setSearchTerm", "notificaciones");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;
    await this.getCities();
    await this.getPushNotifications();
    await this.getUsers();

    this.loading = false;
  },

  methods: {
    title() {
      return "Selecciona las fechas a filtrar";
    },

    async deletePush() {
      this.loading = true;
      this.selectedItem.deletedAt = new Date();
      this.selectedItem.deletedBy = this.$store.state.user[".key"];

      await db.collection("deletedPushNotifications").add(this.selectedItem);

      await db
        .collection("pushNotifications")
        .doc(this.selectedItem[".key"])
        .delete();

      this.loading = false;
      this.cancelDialog = false;
    },
    async cancelPush() {
      this.loading = true;

      await db
        .collection("scheduledPushNotifications")
        .doc(this.selectedItem[".key"])
        .update({
          status: "canceled",
          canceledAt: new Date(),
          canceledBy: this.$store.state.user[".key"],
        });

      this.loading = false;
      this.cancelDialog = false;
    },
    cancel(item) {
      this.selectedItem = item;
      this.cancelDialog = true;
    },
    newNotification(type) {
      this.randomKey = Math.random();
      this.addModal = true;
      this.type = type;
    },
    selectNotificationType() {
      this.selectTypeModal = true;
    },
    handleSuccess(data) {
      this.snackbarText = data;
      this.snackbar = true;
      this.addModal = false;
    },
    closeSelectTypeModal() {
      this.selectTypeModal = false;
      this.type = "";
    },
    getPushNotifications() {
      return new Promise(async (resolve, reject) => {
        await this.$binding(
          "pushNotifications",
          db
            .collection(
              this.tab == 0 ? "pushNotifications" : "scheduledPushNotifications"
            )
            .where(
              "createdAt",
              ">",
              moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
            )
            .where(
              "createdAt",
              "<",
              moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
            )
        );
        resolve("success");
      });
    },
    getUsers() {
      return new Promise((resolve, reject) => {
        let tempUsers = [];
        this.pushNotifications.forEach((notification) => {
          db.collection("appUsers")
            .doc(notification.createdBy)
            .get()
            .then((doc) => {
              tempUsers.push(Object.assign({ id: doc.id }, doc.data()));
            })
            .catch((error) => {
              console.log("error", error);
            });
        });
        this.users = tempUsers;
        return resolve("success");
      });
    },
    getUserName(userId) {
      let user = this.users.find((user) => {
        return user.id === userId;
      });
      return user ? user.name || user.email : "";
    },
    getCities() {
      return new Promise((resolve, reject) => {
        let tempCitiesArr = [];
        db.collection("cities")
          .where("deleted", "==", false)
          .where("active", "==", true)
          .get()
          .then((snapshot) => {
            snapshot.docs.forEach((doc) => {
              tempCitiesArr.push(Object.assign({ id: doc.id }, doc.data()));
            });
          })
          .catch((error) => {
            console.log("error: ", error);
          });
        this.cities = tempCitiesArr;
        resolve("success");
      });
    },
    getCityNames(cityIds) {
      if (cityIds != undefined) {
        const namesArray = this.cities
          .filter((city) => cityIds.some((id) => id === city.id))
          .map((city) => city.name);

        if (namesArray.length > 1) {
          return (
            namesArray.slice(0, namesArray.length - 1).join(", ") +
            " y " +
            namesArray.slice(-1)
          );
        } else if (namesArray.length === 1) {
          return namesArray[0];
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    getVerbiage(count, sendNotification) {
      if (!sendNotification) {
        return "Solo mostrar";
      }
      if (count == null) {
        return "cargando..";
      } else if (count === 0) {
        return "0";
      } else {
        return count;
      }
    },
  },
  filters: {
    formatDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("LL");
    },
    formatFullDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY hh:mm A");
    },
    toSentece(array) {
      if (array != undefined) {
        if (array.length > 1) {
          return (
            array.slice(0, array.length - 1).join(", ") +
            " y " +
            array.slice(-1)
          ).substring(0, 64);
        } else if (array.length === 1) {
          return array[0];
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
  },
  watch: {
    async pushNotifications() {
      this.loading = true;

      for (let j = 0; j < this.pushNotifications.length; j++) {
        const notification = this.pushNotifications[j];
        let userArray = [];

        if (notification.users && notification.users.length) {
          for (let i = 0; i < notification.users.length; i++) {
            const userId = notification.users[i];
            let user = await db.collection("users").doc(userId).get();

            userArray.push(user.data().email);
          }

          notification.userInfo = userArray;
        }
      }

      // this.pushNotifications.forEach(async (notification) => {

      // });

      this.loading = false;
    },
    date(val) {
      if (val.length > 1) {
        this.getPushNotifications();
      }
    },
    tab() {
      this.getPushNotifications();
    },
  },
};
</script>

<style>
.inner-lottie {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
